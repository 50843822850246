import React, { useState } from "react"

const questions = [
  {
    question: "¿Qué necesito para ser Conductor?",
    response: `
    <p>Para ser conductor registrado, vas a requerir:</p>
   <ul>
      <li>Identificación oficial (INE, IFE). Vigente.</li>
      <li>Licencia de conducir, cualquier Tipo. Vigente.</li>
      <li>Tarjeta de circulación del vehículo Vigente</li>
      <li>Seguro del vehículo Vigente</li>
      <li>Documento en donde aparezca tu CURP</li>
      <li>Extranjeros(FM, pasaporte) Vigente</li>
   </ul>
    `,
  },
  {
    question: "¿Qué vehículos puedo registrar en DiDi Conductor?",
    response: `
    <p>Para ser Conductor registrado, vas a requerir:</p>
    <ul>
       <li>Identificación oficial (INE, IFE). Vigente.</li>
       <li>Licencia de conducir. Vigente.</li>
       <li>Tarjeta de circulación del vehículo Vigente.</li>
       <li>Documento en donde aparezca tu CURP.</li>
       <li>Extranjeros(FM, pasaporte) Vigente.</li>
    </ul>
    `,
  },
  {
    question: "¿Cómo me registro para ser Conductor?",
    response: `
    <p>Existen cientos de modelos y marcas de autos en el mercado, nuestro personal de soporte técnico no conoce todas las marcas y modelos ni las características de todos los autos que actualmente existen, por eso simplemente aquí te explicamos:</p>
   <p>Las características de los coches que estamos aceptando actualmente en DiDi son:</p>
   <ul>
      <li>Consulta la antigüedad máxima que aplica en tu ciudad</li>
      <li>4 puertas mínimo.</li>
      <li>En buenas condiciones, sin daños estéticos.</li>
      <li>Capacidad para al menos 4 pasajeros (adicionales al del conductor).</li>
      <li>Ventanas y aire acondicionado con funcionalidad normal.</li>
      <li>Cinturones de seguridad en todos los asientos.</li>
      <li>Frenos ABS.</li>
      <li>Airbags para conductor y pasajero.</li>
      <li>Seguro privado con cobertura amplia.</li>
      <li>No debe tener emblemas comerciales.</li>
      <li>El valor de factura no es relevante.</li>
   </ul>
    `,
  },
]

const QA = props => {
  const [question, setQuestion] = useState(null)

  if (question)
    return (
      <div className="QA">
        <div className="container">
          <div className="row">
            <h2 className="text-white font-weight-bold">
              Preguntas frecuentes
            </h2>

            <div className="QAContainer">
              <div className="questionSelector">
                <span
                  role="presentation"
                  onKeyDown={() => null}
                  onClick={() => setQuestion(null)}
                  className="questionClose"
                >
                  Cerrar
                </span>
                <p>{question.question}</p>
                <div
                  className="questionResponse"
                  dangerouslySetInnerHTML={{ __html: question.response }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="QA">
      <div className="container">
        <div className="row">
          <h2 className="text-white font-weight-bold">Preguntas frecuentes</h2>

          <div className="QAContainer">
            {questions.map((question, index) => (
              <div
                role="presentation"
                onKeyDown={() => null}
                onClick={() => setQuestion(question)}
                key={index}
                className="questionSelector"
              >
                <p>{question.question}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default QA
