import React, { useEffect, useState } from "react"

const Hero = props => {
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    const onScroll = e => {
      setScroll(window.scrollY / 50)
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <header className="masthead">
      <div className="blur" style={{ filter: `blur(${scroll}px)` }} />
      <div className="container px-4 px-lg-5 h-100">
        <div className="row gx-4 gx-lg-5 h-100 align-items-flex-start justify-content-flex-start">
          <div className="col-lg-8 align-self-end">
            <h1 className="text-white font-weight-bold">DiDi pláticas</h1>
          </div>
          <div className="col-lg-5 align-self-baseline">
            <p className="text-white text-white-75 mb-4">
              Conoce todo lo que necesitas saber, desde usar tu app como un
              experto hasta hacer tus impuestos facilito y sin temas. Solo da
              clic en el video que te interese y ¡listo!
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Hero
