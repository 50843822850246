import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"

import "./index.css"

const YOUTUBE_PLAYLIST_ITEMS_API =
  "https://www.googleapis.com/youtube/v3/playlistItems"
const API_KEY = "AIzaSyBbyWzTRu1lS093_5WpzZpMeFWl3ldfMSA"
const PLAYLIST_ID = "PLNdBk0JpgVNCDSLTnCiH6fXbVL2sMSPyr"
const URL = `${YOUTUBE_PLAYLIST_ITEMS_API}?key=${API_KEY}&playlistId=${PLAYLIST_ID}&maxResults=10&part=snippet,contentDetails,status`

const ItemTitle = ({ title = "", className = "", max = 50 }) => (
  <p className={`mb-5 ${className}`}>
    <b>{title.length > max ? `${title.substring(0, max - 3)}...` : title}</b>
  </p>
)
const ItemDescription = ({ desc = "", className = "", max = 150 }) => (
  <span className={`mb-5 ${className}`}>
    {desc.length > max ? `${desc.substring(0, max - 3)}...` : desc}
  </span>
)

const Index = ({ data, location }) => {
  const iframeRef = useRef(null)
  const [currentVideo, setCurrentVideo] = useState(null)
  const [videos, setVideos] = useState([])
  const [posts, setPost] = useState([])

  const siteTitle = data.site.siteMetadata?.title || `Title`

  const onSetVideo = video => {
    setCurrentVideo(video)
    if (iframeRef.current) {
      iframeRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  }

  const getVideoPublishDate = ({ publishedAt }) => {
    return new Date(publishedAt).getTime()
  }

  useEffect(() => {
    fetch(URL)
      .then(res => res.json())
      .then(res => {
        res = res.items || []
        res = res.map(item => ({
          ...item.snippet,
          ...item.contentDetails,
          ...item.status,
          publishedAt: getVideoPublishDate(
            item.snippet || { publishedAt: new Date() }
          ),
        }))
        res = res.sort((a, b) => a.publishedAt - b.publishedAt).reverse()
        setCurrentVideo(res[0])
        setVideos(res)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  /* useEffect(() => {
    fetch(`https://didiwebinars.com/wp-json/wp/v2/posts/?v=${Math.random()}`)
      .then(res => res.json())
      .then(post => {
        setPost(post)
      })
      .catch(err => console.log(err))
  }, []) */

  if (!currentVideo && !videos) return

  return (
    <Layout location={location} title={siteTitle}>
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top py-3"
        id="mainNav"
        style={{ background: "#ff7d41" }}
      >
        <div className="container px-4 px-lg-5">
          <StaticImage
            className="logo"
            loading="eager"
            src="../images/DiDi_logo_web.png"
            alt="Logo"
          />
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto my-2 my-lg-0">
              <li className="nav-item">
                <a className="text-white nav-link" href="#lives">
                  <span>Lives</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="text-white nav-link" href="#siguientes">
                  <span>Siguientes</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="text-white nav-link" href="#pasados">
                  <span>Pasados</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Hero />
      {Boolean(currentVideo) ? (
        <div id="lives" ref={iframeRef} className="player">
          <div className="container">
            <h2 className={`sectionTitle currentVideoTitle`}>Sesión en vivo</h2>
            <div className="playerWrapper">
              <iframe
                id="player"
                allowFullScreen="allowfullscreen"
                title={currentVideo.title}
                type="text/html"
                width="720"
                height="390"
                src={`http://www.youtube.com/embed/${currentVideo.videoId}?enablejsapi=1&?autoplay=1`}
                frameBorder="0"
              ></iframe>
            </div>
            <div className="row currentVideoContainer">
              <h2>{currentVideo.title}</h2>
              <p>{currentVideo.description}</p>
              {/*  <div className="button_container">
                <button type="button" className="btn btn-primary">
                  <a
                    href="https://wa.me/3324721164"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mándanos un mensaje por Whatsapp
                  </a>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div id="lives" ref={iframeRef} className="player">
          <div className="container">
            <h2 className={`sectionTitle currentVideoTitle`}>Sesión en vivo</h2>
            <div className="playerWrapper">
              <iframe
                id="player"
                width="720"
                height="390"
                src={`https://www.youtube.com/embed/videoseries?controls=1&amp;list=${PLAYLIST_ID}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="row currentVideoContainer">
              <div className="button_container">
                {/*  <button type="button" className="btn btn-primary">
                  <a
                    href="https://wa.me/3324721164"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mándanos un mensaje por Whatsapp
                  </a>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/*  <div id="siguientes" className="container">
        <h2 className="sectionTitle webinarsTitle">
          Entérate de nuestras próximas guías y sesiones
        </h2>
        {!Boolean(posts.length) && (
          <h5 style={{ textAlign: "center" }}>
            Pronto tendremos más DiDi pláticas
          </h5>
        )}
        <div className="row nextWebinarContainer">
          {posts.map((post, index) => {
            const {
              title,
              excerpt,
              acf: { fecha },
            } = post
            const postTitle = title.rendered || "Webinar"
            const postExcerpt = excerpt.rendered || ""
            let date = fecha ? new Date(fecha) : new Date()
            let isExpired = date.getTime() - new Date().getTime() <= 0

            if (isExpired) return null

            return (
              <div key={index} className="col-md-4 nextWebinarItem">
                <div className="videoItem">
                  <div className="videoItemDesc">
                    <ItemTitle title={postTitle} />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: postExcerpt,
                      }}
                      itemProp="description"
                    />
                    <br />
                    <p>Fecha: {date.toLocaleDateString()}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div> */}

      <div id="pasados" className="container">
        <h2 className="sectionTitle webinarsTitle">
          Guías que podrían interesarte
        </h2>

        <div className="row webinars">
          {videos.map(video => {
            const { thumbnails, title, description, videoId } = video
            const standard =
              thumbnails && thumbnails.standard ? thumbnails.standard : null
            if (videoId === currentVideo.videoId) return null
            if (!standard) return null
            return (
              <div
                key={videoId}
                className="col-md-4"
                role="presentation"
                onKeyDown={() => null}
                onClick={() => onSetVideo(video)}
              >
                <div className="videoItem">
                  <div className="videoItemThumbnail">
                    <img
                      src={standard && standard.url ? standard.url : ""}
                      alt={title}
                    />
                  </div>
                  <div className="videoItemDesc">
                    <ItemTitle title={title} />
                    <ItemDescription desc={description} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {/*  <div className='row slider'>
        <Slider {...settings}>
          {
            videos.map(video => {
              const { publishedAt, thumbnail, title, description, id } = video
              const date = new Date(publishedAt).toLocaleString('es-MX')
              return <div key={id}>
                <div className='sliderItem'>
                  <img className='sliderItemCover' src={thumbnail.url} alt={title} />
                  <div className='sliderItemContainer'>
                    <div className='sliderItemHeader'>
                      <div className='sliderItemTitle'>
                        <ItemTitle className='text-white' title={title} />
                        <span className='text-white'>{date}</span>
                      </div>
                      <div className='btn btn-primary btn-xl' onClick={() => onSetVideo(video)}>Ver webinar</div>
                    </div>
                    <ItemDescription className='text-white desc' desc={description} max={150} />
                  </div>
                </div>
              </div>
            })
          }
        </Slider>
      </div> */}
      </div>
    </Layout>
  )
}

export default Index

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Inicio" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
