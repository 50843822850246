import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import QyA from "./QyA"

const Footer = () => {
  return (
    <div>
      <QyA />
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <StaticImage
                className="logo"
                loading="eager"
                src="../images/DiDi_logo_web.png"
                alt="Logo"
              />

              <div className="countries">
                <a
                  href="https://argentina.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Argentina
                </a>
                •
                <a
                  href="https://australia.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Australia
                </a>
                •
                <a
                  href="https://99app.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Brasil
                </a>{" "}
                •{" "}
                <a
                  href="https://chile.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chile
                </a>
                •
                <a
                  href="https://colombia.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Colombia
                </a>{" "}
                •{" "}
                <a
                  href="https://costarica.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Costa Rica
                </a>
                •
                <a
                  href="https://ecuador.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ecuador
                </a>{" "}
                •{" "}
                <a
                  href="?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Egipto
                </a>
                •
                <a
                  href="https://didimobility.co.jp/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Japón
                </a>
                •
                <a href="https://mexico.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874">
                  México
                </a>{" "}
                •{" "}
                <a
                  href="https://newzealand.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nueva Zelanda
                </a>
                •
                <a
                  href="https://panama.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Panamá
                </a>
                •
                <a
                  href="https://peru.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Perú
                </a>
                •
                <a
                  href="https://dominicana.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  República Dominicana
                </a>
                •
                <a
                  href="https://russia.didiglobal.com/?utm_source=google&amp;utm_medium=organic&amp;utm_campaign=none&amp;d_ga_id=GA1.2.1139204888.1666398874&amp;d_gcl_au=1.1.607587651.1666398874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rusia
                </a>
              </div>
            </div>
            <div className="col-md-6" />
            <div className="col-md-3">
              <span className="text-white">Siguenos</span>
              <a
                href="https://www.facebook.com/DiDiMexico/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StaticImage
                  className="icon"
                  loading="eager"
                  src="../images/FB.png"
                  alt="Logo"
                />
              </a>
              <a
                href="https://www.instagram.com/didi_mexico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StaticImage
                  className="icon"
                  loading="eager"
                  src="../images/IG.png"
                  alt="Logo"
                />
              </a>
              <a
                href="https://twitter.com/didi_mexico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StaticImage
                  className="icon"
                  loading="eager"
                  src="../images/TW.png"
                  alt="Logo"
                />
              </a>
              <a
                href="https://www.youtube.com/c/DiDiM%C3%A9xico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StaticImage
                  className="icon"
                  loading="eager"
                  src="../images/YT.png"
                  alt="Logo"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
